import Component from "vue-class-component";
import router from "./router";
import { AppBase } from "./../CharginStations/AppBase";

@Component({
  router,
})
export default class App extends AppBase {
  protected mapBoxApiKey = "pk.eyJ1IjoiMjRuZXQiLCJhIjoiY204b3ZxZ25qMDN1cjJrc2ZncWxqcmlpcyJ9.A48xmep-nh_IwUr1Fm4fFg";
  protected googleApiKey = "AIzaSyD1PkDDiaKcK4nNtXfV-Bog7Nk3FD9ZkS4";
  protected sourceUrl = "https://fdrive.cz/data/export/pub/charging-stations-geo.json";
  protected pinColors = {
    fast: "nabijame_blue",
    regular: "nabijame_green",
    selected: "nabijame_dark",
    wip: "nabijame_grey",
    cluster: "nabijame_dark",
  };
  protected allowGeoAccessMessage = "Povoľte prosím prístup k polohovým službám vo Vašom prehliadači pre plnú funkčnosť stránky.";

  protected mounted() {
    this.mounted();
  }

  protected fitCountryInView() {
    super.fitCountryInView();
  }
}
